@import "../../variables.scss";

// $md-radio-checked-color: $vesti-700;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.RadioButton,
.radiooption {
  margin: 10px 0;
  // width: 420px;
  width: 100%;

  @include breakpoint(mobileonly) {
    // width: 300px;
    width: 100%;
  }
  @include rowflexx(flex-start);
  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $vesti-700;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
      //    background-color: black;
      top: 5px;
      left: 5px;
    }

    &:checked + label + div.paymentoption-detail {
      p:nth-child(1) {
        color: $vesti-700;
      }
    }
  }

  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }

    &:after {
      top: calc($md-radio-size / 2) - calc($md-radio-size / 2);
      left: calc($md-radio-size / 2) - calc($md-radio-size / 2);
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $vesti-700;
    }
  }

  .paymentoption-detail {
    margin-left: -25px;
    @include colflexx(flex-start);
    gap: 10px;
    transition: all 0.5s ease;
    p:nth-child(1) {
      margin: 0;
      @include fontBold(1.3em, 1em, $vesti-901);
    }
    p:nth-child(2) {
      margin-top: 10px;
      @include fontNormal(1em, 1em, $vesti-901);
    }
  }
}

.radiooption {
  // width: 100%;
  height: fit-content;
  padding: 20px 20px;
  border-radius: 10px;
  border: 1px solid $gray-300;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.2);
  margin: 0 !important;
  @include rowflex(center, space-between);
  // @include rowflex(flex-start, space-between);

  @include breakpoint(mobileonly) {
    padding: 10px 10px;
  }

  label {
    width: fit-content;
    padding-right: 0px !important;
  }
  &.--active {
    border: 1px solid $vesti-700;
    background-color: $vesti-100;
    .radiooption__left {
      p:nth-child(1) {
        @include fontBold(1.1em, 1em, $vesti-700);

        @include breakpoint(desktop) {
          @include fontBold(1.3em, 1em, $vesti-700);
        }
      }
    }
  }
  &__left {
    @include rowflexx(center);
    gap: 10px;
    img {
      width: 35px;

      @include breakpoint(mobileonly) {
        width: 25px;
      }
    }
  }
  &__titlesub {
    @include colflex(flex-start, space-between);
    transition: all 0.5s ease;
    gap: 10px;

    @include breakpoint(mobileonly) {
      gap: 5px;
    }
    p:nth-child(1) {
      margin: 0;
      @include fontBold(1.1em, 1em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontBold(1em, 1em, $vesti-901);
      }
      @include breakpoint(desktop) {
        @include fontBold(1.3em, 1em, $vesti-901);
      }
    }
    p:nth-child(2) {
      margin-top: 10px;
      margin-bottom: 0px;
      @include fontNormal(0.85em, 1.2em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontNormal(0.75em, 1.2em, $vesti-901);
      }
      @include breakpoint(desktop) {
        @include fontNormal(1em, 1.2em, $vesti-901);
      }
    }
  }
}


.RadioButton,
.verysmallradiooption,
.Newverysmallradiooption,
.clarityradiooption {
  margin: 10px 0;
  // width: 420px;
  width: 50%;

  @include breakpoint(mobileonly) {
    // width: 300px;
    width: 100%;
  }
  @include rowflexx(flex-start);
  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $vesti-700;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
      //    background-color: black;
      top: 5px;
      left: 5px;
    }

    &:checked + label + div.paymentoption-detail {
      p:nth-child(1) {
        color: $vesti-700;
      }
    }
  }

  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 2px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }

    &:after {
      top: calc($md-radio-size / 2) - calc($md-radio-size / 2);
      left: calc($md-radio-size / 2) - calc($md-radio-size / 2);
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $vesti-700;
    }
  }

  .paymentoption-detail {
    margin-left: -25px;
    @include colflexx(flex-start);
    gap: 10px;
    transition: all 0.5s ease;
    p:nth-child(1) {
      margin: 0;
      @include fontBold(1.3em, 1em, $vesti-901);
    }
    p:nth-child(2) {
      margin-top: 10px;
      @include fontNormal(1em, 1em, $vesti-901);
    }
  }
}

.clarityradiooption {
  width: 100%;
  height: fit-content;
  padding: 20px 20px;
  border-radius: 10px;
  border: 1px solid $gray-300;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.2);
  margin: 0 !important;
  gap: 10px;
  @include rowflex("", space-between);
  // @include rowflex(flex-start, space-between);

  @include breakpoint(mobileonly) {
    padding: 10px 10px;
  }

  label {
    width: fit-content;
    padding-right: 0px !important;
  }
  &.--active {
    border: 1px solid $vesti-700;
    background-color: $vesti-100;
    .verysmallradiooption__left {
      p:nth-child(1) {
        @include fontBold(1.1em, 1em, $vesti-700);

        @include breakpoint(desktop) {
          @include fontBold(1.3em, 1em, $vesti-700);
        }
      }
    }
  }
  &__left {
    @include rowflexx("");
    gap: 10px;
    img {
      width: 35px;

      @include breakpoint(mobileonly) {
        width: 25px;
      }
    }
  }
  &__titlesub {
    @include colflex(flex-start, space-between);
    transition: all 0.5s ease;
    gap: 2px;

    @include breakpoint(mobileonly) {
      gap: 5px;
    }
    p:nth-child(1) {
      margin: 0;
      font-size: 16px;
      font-weight: 600;

      // @include breakpoint(mobileonly) {
      //   @include fontBold(1em, 1em, $vesti-901);
      // }
      // @include breakpoint(desktop) {
      //   @include fontBold(1.3em, 1em, $vesti-901);
      // }
    }
    p:nth-child(2) {
      margin-top: 4px;
      margin-bottom: 0px;
      font-size: 14px;
      color: #3e6f26;
      // @include fontNormal(0.85em, 1.2em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontNormal(0.75em, 1.2em, $vesti-901);
      }
      @include breakpoint(desktop) {
        font-weight: 400;
        // @include fontNormal(0.9em, 1.7em, $vesti-901);
      }
    }
  }
}

.verysmallradiooption {
  width: 100%;
  height: fit-content;
  padding: 20px 20px;
  border-radius: 10px;
  border: 1px solid $gray-300;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.2);
  margin: 0 !important;
  @include rowflex(center, space-between);
  // @include rowflex(flex-start, space-between);

  @include breakpoint(mobileonly) {
    padding: 10px 10px;
  }

  label {
    width: fit-content;
    padding-right: 0px !important;
  }
  &.--active {
    border: 1px solid $vesti-700;
    background-color: $vesti-100;
    .verysmallradiooption__left {
      p:nth-child(1) {
        @include fontBold(1.1em, 1em, $vesti-700);

        @include breakpoint(desktop) {
          @include fontBold(1.3em, 1em, $vesti-700);
        }
      }
    }
  }
  &__left {
    @include rowflexx(center);
    gap: 10px;
    img {
      width: 35px;

      @include breakpoint(mobileonly) {
        width: 25px;
      }
    }
  }
  &__titlesub {
    @include colflex(flex-start, space-between);
    transition: all 0.5s ease;
    gap: 10px;

    @include breakpoint(mobileonly) {
      gap: 5px;
    }
    p:nth-child(1) {
      margin: 0;
      @include fontBold(1.1em, 1em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontBold(1em, 1em, $vesti-901);
      }
      @include breakpoint(desktop) {
        @include fontBold(1.3em, 1em, $vesti-901);
      }
    }
    p:nth-child(2) {
      margin-top: 10px;
      margin-bottom: 0px;
      @include fontNormal(0.85em, 1.2em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontNormal(0.75em, 1.2em, $vesti-901);
      }
      @include breakpoint(desktop) {
        font-weight: 400;
      }
    }
  }
}

.savingsradiooption {
  width: 100%;
  height: fit-content;
  padding: 20px 20px;
  border-radius: 10px;
  border: 1px solid $gray-300;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.2);
  margin: 0 !important;
  @include rowflex(flex-start, space-between);

  @include breakpoint(mobileonly) {
    padding: 10px 10px;
  }

  label {
    width: fit-content;
    padding-right: 0px !important;
  }
  &.--active {
    border: 1px solid $vesti-700;
    background-color: $vesti-100;
    .radiooption__left {
      p:nth-child(1) {
        @include fontBold(1.1em, 1em, $vesti-700);

        @include breakpoint(desktop) {
          @include fontBold(1.3em, 1em, $vesti-700);
        }
      }
    }
  }
  &__left {
    @include rowflexx(flex-end);
    gap: 10px;
    img {
      width: 35px;

      @include breakpoint(mobileonly) {
        width: 25px;
      }
    }
  }
  &__titlesub {
    @include colflex(flex-start, space-between);
    transition: all 0.5s ease;
    gap: 10px;

    @include breakpoint(mobileonly) {
      gap: 5px;
    }
    p:nth-child(1) {
      margin: 0;
      @include fontBold(1.1em, 1em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontBold(1em, 1em, $vesti-901);
      }
      @include breakpoint(desktop) {
        @include fontBold(1.3em, 1em, $vesti-901);
      }
    }
    p:nth-child(2) {
      margin-top: 10px;
      margin-bottom: 0px;
      @include fontNormal(0.85em, 1.2em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontNormal(0.75em, 1.2em, $vesti-901);
      }
      @include breakpoint(desktop) {
        @include fontNormal(1em, 1.2em, $vesti-901);
      }
    }
  }
}

///////
///
.Newverysmallradiooption {
  width: 100%;
  height: fit-content;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid $gray-300;
  background-color: #fff;
  // box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.2);
  margin: 0 !important;
  display: flex;
  align-items: center;
  gap: 6px;
  // @include rowflex(flex-start, space-between);

  @include breakpoint(mobileonly) {
    padding: 5px 5px;
  }

  label {
    width: fit-content;
    padding-right: 0px !important;
  }
  &.--active {
    border: 1px solid $vesti-700;
    background-color: $vesti-100;
    .vNewverysmallradiooption__left {
      p:nth-child(1) {
        @include fontBold(1.1em, 1em, $vesti-700);

        @include breakpoint(desktop) {
          @include fontBold(1.3em, 1em, $vesti-700);
        }
      }
    }
  }
  &__left {
    @include rowflexx(center);
    gap: 10px;
    img {
      width: 35px;

      @include breakpoint(mobileonly) {
        width: 25px;
      }
    }
  }
  &__titlesub {
    @include colflex(flex-start, space-between);
    transition: all 0.5s ease;
    gap: 10px;

    @include breakpoint(mobileonly) {
      gap: 5px;
    }
    p:nth-child(1) {
      margin: 0;
      @include fontBold(1.1em, 1em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontBold(1em, 1em, $vesti-901);
      }
      @include breakpoint(desktop) {
        @include fontBold(1.3em, 1em, $vesti-901);
      }
    }
    p:nth-child(2) {
      margin-top: 10px;
      margin-bottom: 0px;
      @include fontNormal(0.85em, 1.2em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontNormal(0.75em, 1.2em, $vesti-901);
      }
      @include breakpoint(desktop) {
        @include fontNormal(1em, 1.2em, $vesti-901);
      }
    }
  }
}
